import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './termsofuse.module.scss'

const TermsPage = ({ pageContext: { locale, hrefLangs }, data }) => {
  const content = data.allContentfulTermsOfUse.edges[0].node
  return (
    <Layout locale={locale}>
      <SEO
        title={content.title}
        description={content.title}
        hrefLangs={hrefLangs}
      />
      <main role="main" className={styles.main}>
      <div className="wrapper">
        <section className={styles.termsofuse}>
          <h1>
            <FormattedMessage id="termsofuse" />
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: content.content.childMarkdownRemark.html,
            }}
          ></div>
        </section>
      </div>
      </main>
    </Layout>
  )
}

export default TermsPage

export const query = graphql`
  query($localeLanguage: String!) {
    allContentfulTermsOfUse(filter: { node_locale: { eq: $localeLanguage } }) {
      edges {
        node {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
